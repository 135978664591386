import React, { Component } from 'react';
import GroupCard from '../Cards/GroupCard';
import Collapsible from 'react-collapsible';
import './Group.css'

class Group extends Component {
  constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
      };
      this.myRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // respond to parameter change in scenario 3
    let oldTime = prevProps.match.params.date
    let newTime = this.props.match.params.date
    if (newTime !== oldTime)
      this.fetchData();
  }

  fetchData() {
    fetch('https://stfainfo.ch/api/v1/getGroupTable?tournamentDate=' + this.props.match.params.date, {
        mode: 'cors'
      })
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  printGroupTable(items) {
    return <div > {
      items.map(item => (
        <GroupCard
        groupId = {item["groupId"]}
        tournamentDate = {item["tournamentDate"]}
        teams = {item["teams"]}/>
      ))
    }</div > ;
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
          <div className="Group-content" ref={this.myRef}>{this.printGroupTable(items)}</div>
      );
    }
  }
}

export default Group;
