import React, { Component } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import './MenuButton.css';

class MenuButton extends Component {
  render() {
    return (
      <button id="menuicon"
              onMouseUp={this.props.handleMouseDown}>
              <MenuIcon/>
      </button>
    );
  }
}

export default MenuButton;
