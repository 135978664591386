import React, { Component } from 'react';
import {
  NavLink
} from "react-router-dom";
import './TournamentCard.css';

class TournamentCard extends React.Component {
  render() {
    return (
      <div className="TournamentCard">
      <NavLink to={"/event/" + this.props.tournDate + "/overview"}>
        <div className="TournamentCard_header">
          <div className="TournamentCard_season">
          season {this.props.tournSeason.substring(4,8)+"/"+this.props.tournSeason.substring(0,4)}</div>
          <div className="TournamentCard_date">
          {this.props.tournDate.substring(6,8) + "." + this.props.tournDate.substring(4,6) + "."
         + this.props.tournDate.substring(0,4)}</div>
        </div>
        <div className="TournamentCard_content" style={this.props.tournType == 4 ? {background: "#BEBEBE"} : {}}>
          <div>
            <img src={process.env.PUBLIC_URL + '/logos/logo_'+ ((this.props.tag==="") ? "stfa":this.props.tag.toLowerCase()) + '.png'}
            alt="img" className="TournamentCard_logo"/>
          </div>
          <div className="TournamentCard_infocolumn">
            <div className="TournamentCard_name">{this.props.tournName}</div>
            <div className="TournamentCard_city">{this.props.tournCity}</div>
            <div className="TournamentCard_type" style={this.props.tournType == 4 ? {display: "none"} : {}}>{(this.props.tournType == 0) ? "Indoor" : "Outdoor"}</div>
          </div>
        </div>
      </NavLink>
      </div>
    );
  }
}


export default TournamentCard;
