import React, { Component } from 'react';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Table from "./Content/Table";
import TournamentsTopLevel from "./Content/TournamentsTopLevel";
import EventTopLevel from "./Content/EventTopLevel";
import MenuDrawer from "./Menu/MenuDrawer";
import './App.css';

class App extends Component {
  render() {
    return (
        <div className="App">
          <HashRouter>
            <div className="mainMenu">
              <div className="drawerMenu">
                <MenuDrawer />
              </div>
              <div className="logo">
                TamilLeague
              </div>
            </div>
            <div className="content">
              <Route path="/table" component={Table}/>
              <Route path="/tournaments" component={TournamentsTopLevel}/>
              <Route path="/event/:date" component={EventTopLevel}/>
            </div>
          </HashRouter>
        </div>
    );
  }
}

export default App;
