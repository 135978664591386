import React, { Component } from 'react';
import './FixtureCard.css';

function printPenaltyResult(){
  if (this.props.penaltyA =="0" && this.props.penaltyB == "0") {
    return this.props.penaltyA + " : " + this.props.penaltyB;
  } else {
    return "";
  }
}

class FixtureCard extends React.Component {
  render() {
    return (
      <div className="FixtureCard">
        <div className="Team">
          <div><img src={process.env.PUBLIC_URL + '/logos/logo_'+ ((this.props.teamALogo==="") ? "stfa":this.props.teamALogo.toLowerCase()) + '.png'}
          alt="logo" className="Team_logo"/></div>
          <div className="Team_name">{this.props.teamAName}</div>
        </div>
        <div>
          <div className="info">{"group " + this.props.group + " - " + this.props.time}</div>
          <div className="result">{
            (((this.props.penaltyA+this.props.penaltyB) < 1) ? ( (this.props.resultA >= 0 && this.props.resultB >= 0) ? this.props.resultA+" : "+this.props.resultB : "- : -") : (this.props.penaltyA+" : "+this.props.penaltyB+" *P"))
           }</div>
          <div className="penaltyResult">{
            (((this.props.penaltyA+this.props.penaltyB) > 0) ? (this.props.resultA+" : "+this.props.resultB) : "")
          }</div>
        </div>
        <div className="Team">
          <div><img src={process.env.PUBLIC_URL + '/logos/logo_'+ ((this.props.teamBLogo==="") ? "stfa":this.props.teamBLogo.toLowerCase()) + '.png'}
          alt="logo" className="Team_logo"/></div>
          <div className="Team_name">{this.props.teamBName}</div>
        </div>
      </div>
    );
  }
}


export default FixtureCard;
