import React, { Component } from 'react';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import './EventTopLevel.css'
import Event from "./Event";
import Fixture from "./Fixture";
import Group from "./Group";

class EventTopLevel extends Component {

  render() {
      return (
        <HashRouter>
          <div className="eventHorizontalMenu">
            <ul>
            <li><NavLink to={"/event/" + this.props.match.params.date + "/myteam"}><span className="menuTextHori">MY TOURNAMENT</span></NavLink></li>
            <li><NavLink to={"/event/" + this.props.match.params.date + "/overview"}><span className="menuTextHori">EVENT INFO</span></NavLink></li>
            <li><NavLink to={"/event/" + this.props.match.params.date + "/fixtures"}><span className="menuTextHori">FIXTURES</span></NavLink></li>
            <li><NavLink to={"/event/" + this.props.match.params.date + "/groups"}><span className="menuTextHori">GROUPS</span></NavLink></li>
            </ul>
          </div>
          <div className="contentEvent">
            <Route path="/event/:date/overview" component={Event}/>
            <Route path="/event/:date/fixtures" component={Fixture}/>
            <Route path="/event/:date/groups" component={Group}/>
          </div>
        </HashRouter>);
    }
}

export default EventTopLevel;
