import React, { Component } from 'react';
import './GroupCard.css';

class GroupCard extends React.Component {
  render() {
    return (
      <div className="GroupCard">
       <div className="groupHeader">Group {this.props.groupId}</div>
       <div className="gTinfo"><div>Rank</div><div>Team</div><div>W D L |&nbsp;&nbsp;Diff | &nbsp;  Points</div></div>
       {
         this.props.teams.map(item => (
           <div className="groupTeamItem">
            <div className="groupTeamItemLeft">
              <div className="gTrank">{this.props.teams.indexOf(item)+1}</div>
              <div className="gTlogo"><img src={process.env.PUBLIC_URL + '/logos/logo_'+ ((item["teamId"]==="") ? "stfa":item["teamId"]) + '.png'}
              alt="logo" className="gTlogoImg"/></div>
              <div className="gTname">{item["teamName"]}</div>
            </div>
            <div className="groupTeamItemRight">
              <div className="gTstat">{item["totalWon"]} | {item["totalDraw"]} | {item["totalLoss"]}</div>
              <div className="gTdiff">{item["totalGoalDiff"]}</div>
              <div className="gTpoints">{item["totalPoints"]}</div>
            </div>
           </div>
         ))
       }
      </div>
    );
  }
}

export default GroupCard;
