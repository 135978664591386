import React, { Component } from 'react';
import TournamentCard from '../Cards/TournamentCard';
import './Tournaments.css'

class Tournaments extends Component {
  constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
      };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // respond to parameter change in scenario 3
    let oldTime = prevProps.match.params.time
    let newTime = this.props.match.params.time
    if (newTime !== oldTime)
      this.fetchData();
  }

  fetchData() {
    fetch('https://stfainfo.ch/api/v1/getTournaments?time=' + this.props.match.params.time, {
        mode: 'cors'
      })
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
          <div className="Tournaments-content">
          {items.map(item => (
            <TournamentCard key={item["tourn_id"]} tag={item["tourn_organizer"]}
            tournName={item["tourn_name"]} tournType={item["tourn_type"]}
            tournCity={item["tourn_city"]} tournDate={item["tourn_date"]}
            tournSeason={item["tourn_season"]}/>
          ))}
          </div>
      );
    }
  }
}

export default Tournaments;
