import React, { Component } from 'react';
import TableCard from '../Cards/TableCard';
import './Table.css'

class Table extends Component {
  constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
      };
  }

  componentDidMount() {
    fetch("https://stfainfo.ch/api/v1/stfatable", {mode: 'cors'})
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    var i = 1;
    console.log(this.state);
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
          <div className="Table-content">
          {items.map(item => (
            <TableCard key={item["name"]} tag={item["name"]}
            teamRank={i++} teamName={item["fullname"]}
            teamCity={item["city"]} teamPoints={item["punkte"]}/>
          ))}
          </div>
      );
    }
  }
}

export default Table;
