import React, { Component } from 'react';
import './Event.css'

class Event extends Component {
  constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
      };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // respond to parameter change in scenario 3
    let oldTime = prevProps.match.params.date
    let newTime = this.props.match.params.date
    if (newTime !== oldTime)
      this.fetchData();
  }

  fetchData() {
    fetch('https://stfainfo.ch/api/v1/getTournaments?tournamentDate=' + this.props.match.params.date, {
        mode: 'cors'
      })
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
          <div className="Event-content">
           <div className="Event_header">
            <div className="Event_headerTitle">{items[0].tourn_name}</div>
            <div className="Event_headerSubTitle">சுற்றுப்போட்டி</div>
            <div>
              <img src={process.env.PUBLIC_URL + '/logos/logo_'+ ((items[0].tourn_organizer==="") ? "stfa":items[0].tourn_organizer.toLowerCase()) + '.png'}
              alt="img" className="Event_headerLogo"/>
            </div>
            <div className="Event_headerDate">
              {(items[0].tourn_date).substring(6,8) + "." + (items[0].tourn_date).substring(4,6) + "."+ (items[0].tourn_date).substring(0,4)}</div>
           </div>
          </div>
      );
    }
  }
}

export default Event;
