import React, { Component } from 'react';
import './TableCard.css';

class TableCard extends React.Component {
  render() {
    return (
      <div className="TableCard">
        <div className="TableCard_left">
          <div className="TableCard_rank">{this.props.teamRank}</div>
          <div>
            <img src={process.env.PUBLIC_URL + '/logos/logo_'+ this.props.tag + '.png'}
            alt="logo" className="TableCard_logo"/>
          </div>
          <div className="TableCard_infocolumn">
            <div className="TableCard_name">{this.props.teamName}</div>
            <div className="TableCard_city">{this.props.teamCity}</div>
          </div>
        </div>
        <div className="TableCard_right">
          <div className="TableCard_points">{this.props.teamPoints}</div>
        </div>
      </div>
    );
  }
}


export default TableCard;
