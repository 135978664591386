import React, { Component } from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  NavLink
} from "react-router-dom";
import "./Menu.css";

class Menu extends Component {
  render() {
    var visibility = "hide";

    if (this.props.menuVisibility) {
      visibility = "show";
    }

    //this can be sperated into own class & with property definition
    return (
      <div id="flyoutMenu"
           onMouseUp={this.props.handleMouseDown}
           className={visibility}>
       <div className="drawerInfo">
       <ArrowBackIosIcon/>
       </div>
       <ul className="header">
         <li>
           <NavLink to="/table">
           <i className="icon_table"/><span className="menuText">Table</span></NavLink></li>
         <li>
           <NavLink to="/tournaments/future">
           <div className="icon_tournament"></div><span className="menuText">Tournament</span></NavLink></li>
         <li>
           <NavLink to="/table">
           <div className="icon_stfa"></div><span className="menuText">STFA League</span></NavLink></li>
         <li>
            <NavLink to="/event">
            <div className="icon_soccer"></div><span className="menuText">MatchDay</span></NavLink></li>
         <li>
           <NavLink to="/table">
           <div className="icon_teams"></div><span className="menuText">Teams</span></NavLink></li>
         <li>
           <NavLink to="/table">
           <div className="icon_settings"></div><span className="menuText">Settings</span></NavLink></li>
       </ul>
       <hr />
      </div>
    );
  }
}

export default Menu;
