import React, { Component } from 'react';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import './TournamentsTopLevel.css'
import Tournaments from "./Tournaments";

class TournamentsTopLevel extends Component {

  render() {
      return (
        <HashRouter>
          <div className="horizontalMenu">
            <ul>
            <li><NavLink to="/tournaments/future"><span className="menuTextHori">NEXT TOURNAMNETS</span></NavLink></li>
            <li><NavLink to="/tournaments/past"><span className="menuTextHori">PAST TOURNAMNETS</span></NavLink></li>
            </ul>
          </div>
          <div className="contentTournament">
            <Route path="/tournaments/:time" component={Tournaments}/>
          </div>
        </HashRouter>);
    }
}

export default TournamentsTopLevel;
