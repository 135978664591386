import React, { Component } from 'react';
import FixtureCard from '../Cards/FixtureCard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapsible from 'react-collapsible';
import './Fixture.css'

class Fixture extends Component {
  constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
      };
      this.myRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // respond to parameter change in scenario 3
    let oldTime = prevProps.match.params.date
    let newTime = this.props.match.params.date
    if (newTime !== oldTime)
      this.fetchData();
  }

  fetchData() {
    fetch('https://stfainfo.ch/api/v1/getFixtures?tournamentDate=' + this.props.match.params.date, {
        mode: 'cors'
      })
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  printTableForFixtureSection(title, items, min, max, id){
    return <div><div id={id}>{items.filter(item => item.matchid < max && item.matchid >= min).map(item => (
                <FixtureCard key={item["matchid"]} teamALogo={item["A"]} teamAName={item["teamA"]}
                teamBLogo={item["B"]} teamBName={item["teamB"]} group={item["groupe"]}
                resultA={item["resultA"]} resultB={item["resultB"]} penaltyA={item["penA"]}
                penaltyB={item["penB"]} time={item["time"]}/>
              ))}</div></div>;
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
          <div className="Fixture-content" ref={this.myRef}>
          <Collapsible trigger="Group matches" open="true">
            {this.printTableForFixtureSection("Group matches",items, 0, 70, "gr")}
          </Collapsible>
          <Collapsible trigger="Last sixteen" open="true">
            {this.printTableForFixtureSection("Last sixteen",items, 70, 80, "ls")}
          </Collapsible>
          <Collapsible trigger="Quarterfinal" open="true">
           {this.printTableForFixtureSection("Quarterfinal", items, 80, 85, "qf")}
          </Collapsible>
          <Collapsible trigger="Semifinal" open="true">
          {this.printTableForFixtureSection("Semifinal", items, 85, 90, "sm")}
          </Collapsible>
          <Collapsible trigger="Smallfinal" open="true">
          {this.printTableForFixtureSection("Smallfinal", items, 90, 91, "sf")}
          </Collapsible>
          <Collapsible trigger="Final" open="true">
          {this.printTableForFixtureSection("Final", items, 91, 92, "fi")}
          </Collapsible>
          </div>
      );
    }
  }
}

export default Fixture;
